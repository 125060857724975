import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import { REGISTER_OPTIN_PARAM, REGISTER_SIGNUP_PARAM, ROUTE_HOME } from '../../../../config/routes';
import GlobalLogin, { viewType } from '../../../../global/GlobalLogin';
import { getDecryptedData } from '../../../../utils/crypto-utils';
import { finishProcess } from '../../../../utils/login';
import { useFirebaseAuth } from '../../../../../global/FirebaseProvider/FirebaseProvider';

import './Login.scss';

const Login = ({
  storeConfig,
  onSubmit,
  onError,
  emailLinkParams,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData } = useFirebaseAuth();
  const posthog = usePostHog();

  const [emailLinkSent, setEmailLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finishProcessData, setFinishProcessData] = useState({});

  const redirect = useCallback(async () => {
    setIsLoading(true);

    const {
      error: userError,
      uid: userUid,
    } = userData;

    if (userError) {
      onError(userError);
      setIsLoading(false);
      return;
    }

    if (emailLinkSent) {
      history.push(ROUTE_HOME);
      return;
    }

    const query = new URLSearchParams(search);
    const data = await getDecryptedData(query, userUid);
    await finishProcess({
      ...finishProcessData,
      ...(REGISTER_SIGNUP_PARAM in data && { userRegistered: data[REGISTER_SIGNUP_PARAM] }),
      ...(REGISTER_OPTIN_PARAM in data && { optIn: data[REGISTER_OPTIN_PARAM] }),
      userData,
      search,
      posthog,
    });

    onSubmit(data);
  }, [userData, onSubmit, onError, emailLinkSent, search, finishProcessData]);

  const handleOnEmailLinkSent = useCallback(() => {
    setEmailLinkSent(true);
  }, []);

  useEffect(() => {
    if (isSignedIn && userData && !isLoadingFirebaseAuth) {
      redirect();
    }
  }, [isSignedIn, userData, isLoadingFirebaseAuth]);

  return (
    <div className="RegisterLogin">
      <GlobalLogin
        initialView={viewType.VIEW_EMAIL_FORM}
        isParentLoading={isLoading || isLoadingFirebaseAuth}
        storeConfig={storeConfig}
        emailLinkParams={emailLinkParams}
        onEmailLinkSent={handleOnEmailLinkSent}
        setFinishProcessData={setFinishProcessData}
        isEmbedded
      />
    </div>
  );
};

export default Login;
