import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CardSection from '../../Components/CardSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { TbbCardBalanceSection, TbbSpendSection } from '../../../../global/RegisterTbb/RewardCouponScreen';
import RewardCards from '../../../../images/rewardsCards.png';
import Dialog from '../../../../global/Mui/Dialog';
import Check from '../../../../images/check-green-white.svg';
import Send from '../../../../images/icon-send.svg';
import TCicon from '../../../../images/tc-icon-black-stroke.svg';
import { getNextButtonStyles, getOptionButtonStyles } from '../../../../utils/tbbRegistration';
import { ROUTE_REWARDS } from '../../../../config/routes';
import ShippingProviderLocations from '../SelectShippingProviderView/ShippingProviderLocations';

import './ShippingAndRewards.scss';

const DIALOG_TYPE = {
  SUCCESS: 'success',
  LOCATIONS: 'locations',
};

const ShippingAndReward = ({
  storeConfig,
  onClick,
  shippingMethod,
  creditAmt,
  couponData,
  promoCreditAmount,
  zipcode,
}) => {
  const history = useHistory();

  const [dialogType, setDialogType] = useState(DIALOG_TYPE.SUCCESS);
  const [isOpenDialog, setIsOpenDialog] = useState(true);

  const {
    shippingAndRewardTitle,
    ctaLabel,
    ctaLabel2,
    findLocation,
    locations,
  } = useMemo(() => (
    shippingMethod?.configuration ?? {}
  ), [shippingMethod]);

  const handleDialogOnClose = useCallback(() => {
    setIsOpenDialog(false);
  }, [dialogType, locations]);

  const handleFindLocationsOnClick = useCallback(() => {
    if (locations?.enabled) {
      setDialogType(DIALOG_TYPE.LOCATIONS);
      setIsOpenDialog(true);
      return;
    }

    window.open(findLocation, '_blank');
  }, []);

  return (
    <div className="ShippingAndReward">
      <div className="ShippingAndReward__container">
        <CardSection title={shippingAndRewardTitle}>
          <div className="ShippingAndReward__container--shipping">
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={onClick}
              style={getNextButtonStyles(storeConfig)}
            >
              {ctaLabel}
            </Button>
            {(findLocation || locations?.enabled) && (
              <Button
                type={BUTTON_TYPE.QUINARY}
                size={BUTTON_SIZE.LARGE}
                onClick={handleFindLocationsOnClick}
                style={getOptionButtonStyles(storeConfig, true)}
              >
                {ctaLabel2}
              </Button>
            )}
            {locations?.nextImage && (
              <img
                src={locations?.nextImage}
                alt="shipping provider next"
                className="ShippingAndReward__container--shipping-img"
              />
            )}
          </div>
        </CardSection>
        {!storeConfig.isTrashie && (
          <CardSection title="YOUR Exclusive reward">
            <div className="ShippingAndReward__container--coupon">
              {storeConfig.registerTbbFlow.rewardStep?.couponDescription && (
                <div className="ShippingAndReward__container--coupon--description">
                  {storeConfig.registerTbbFlow.rewardStep.couponDescription}
                </div>
              )}
              <TbbCardBalanceSection
                storeConfig={storeConfig}
                couponData={couponData}
                couponCreditAmt={storeConfig?.registerTbbFlow?.rewardStep?.cashValue}
              />
            </div>
          </CardSection>
        )}
        {storeConfig.registerTbbFlow?.rewardStep?.hideRewards ? (
          <CardSection
            title={`SHOP${storeConfig.title ? ` WITH ${storeConfig.title.toUpperCase()}` : ''}`}
          >
            <TbbSpendSection storeConfig={storeConfig} />
          </CardSection>
        ) : (
          <CardSection
            title="trashiecash™ rewards"
            footer={<img src={RewardCards} alt="rewards" className="ShippingAndReward__container--rewardImage" />}
          >
            <div className="ShippingAndReward__container--reward">
              <div className="ShippingAndReward__container--reward--description">
                Explore exclusive rewards for everyday purchases
              </div>
              <Button
                type={BUTTON_TYPE.QUINARY}
                size={BUTTON_SIZE.LARGE}
                onClick={() => history.push(ROUTE_REWARDS)}
                style={getOptionButtonStyles(storeConfig, true)}
              >
                view all rewards
              </Button>
            </div>
          </CardSection>
        )}
      </div>
      <Dialog
        open={isOpenDialog}
        onClose={handleDialogOnClose}
      >
        {dialogType === DIALOG_TYPE.LOCATIONS ? (
          <ShippingProviderLocations
            locations={locations}
            zipcode={zipcode}
            logoRect={locations?.logoRect}
          />
        ) : (
          <div className="ShippingAndReward__dialog">
            <div className="ShippingAndReward__dialog--header">
              <img src={Check} alt="check" className="ShippingAndReward__dialog--header--icon" />
            </div>
            <div className="ShippingAndReward__dialog--content">
              <div className="ShippingAndReward__dialog--content--title">
                BAG REGISTERED AND
                READY FOR DROP OFF!
              </div>
              <div className="ShippingAndReward__dialog--content--mesages">
                <div className="ShippingAndReward__dialog--content--mesages--message">
                  <img src={Send} alt="send" />
                  {' '}
                  Label sent via email
                </div>
                {!storeConfig.registerTbbFlow?.rewardStep?.hideRewards && (
                  <div className="ShippingAndReward__dialog--content--mesages--message">
                    <img src={TCicon} alt="trashie-cash" />
                    {' '}
                    {Number(creditAmt) + Number(promoCreditAmount)}
                    {' '}
                    TrashieCash™ added to
                    your balance
                  </div>
                )}
              </div>
              <Button
                type={BUTTON_TYPE.QUATERNARY}
                size={BUTTON_SIZE.LARGE}
                onClick={handleDialogOnClose}
                style={getNextButtonStyles(storeConfig)}
              >
                CONTINUE
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ShippingAndReward;
