import { useCallback, useEffect, useState } from 'react';

import InfoSection from '../../../../global/InfoSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import ShippingProviderSelectItem from './ShippingProviderSelectItem';
import { fetchShippingProviders } from '../../../../utils/service';
import LoadingBar from '../../../../global/LoadingBar';
import { getNextButtonStyles, getOptionButtonStyles } from '../../../../utils/tbbRegistration';

import './SelectShippingProviderView.scss';

const SelectShippingProviderView = ({
  setShippingMethod,
  storeConfig,
  onSubmitShippingMethod,
  selectedShippingMethodIndex,
  setSelectedShippingMethodIndex,
  address,
  onError,
  recyclingProductType,
}) => {
  const [shippingProviders, setShippingProviders] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getShippingProviders = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error,
    } = await fetchShippingProviders({
      storeUid: storeConfig.uid,
      storeId: storeConfig.id,
      recyclingProductType,
    });

    if (error) {
      onError();
      setIsLoading(false);
      return;
    }

    const sortedShippingProviders = data.sort((a, b) => a.order - b.order);
    setShippingProviders(sortedShippingProviders);
    setShippingMethod(sortedShippingProviders[0]);
    setIsLoading(false);
  });

  const onSelectShippingProvider = useCallback((index) => {
    setSelectedShippingMethodIndex(index);
    setShippingMethod(shippingProviders[index]);
  }, [shippingProviders]);

  useEffect(() => {
    getShippingProviders();
  }, []);

  return (
    <div className="SelectShippingProviderView">
      <InfoSection titleText="DROP OFF LOCATION" />
      {isLoading ? (
        <LoadingBar />
      ) : (
        <>
          <div className="SelectShippingProviderView__items">
            {shippingProviders[0] && (
              <ShippingProviderSelectItem
                key={shippingProviders[0].uid}
                index={0}
                shippingProvider={shippingProviders[0]}
                selectedIndex={selectedShippingMethodIndex}
                zipcode={address.zip}
                onSelect={onSelectShippingProvider}
                storeConfig={storeConfig}
              />
            )}
            {!collapsed && shippingProviders.slice(1).map((shippingProvider, index) => (
              <ShippingProviderSelectItem
                key={shippingProvider.uid}
                index={index + 1}
                shippingProvider={shippingProvider}
                selectedIndex={selectedShippingMethodIndex}
                zipcode={address.zip}
                onSelect={onSelectShippingProvider}
                storeConfig={storeConfig}
              />
            ))}
          </div>
          {shippingProviders.length > 1 && collapsed && (
            <Button
              type={BUTTON_TYPE.LINK_QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => setCollapsed(false)}
              style={getOptionButtonStyles(storeConfig)}
            >
              SHOW OTHER OPTIONS
            </Button>
          )}
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => onSubmitShippingMethod()}
            style={getNextButtonStyles(storeConfig)}
            disabled={!shippingProviders[selectedShippingMethodIndex]}
          >
            {`CONTINUE${shippingProviders[selectedShippingMethodIndex]?.name ? ` WITH ${shippingProviders[selectedShippingMethodIndex].name}` : ''}`}
          </Button>
        </>
      )}
    </div>
  );
};

export default SelectShippingProviderView;
