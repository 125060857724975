/* eslint-disable no-debugger */
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

import GlobalLogin from '../../global/GlobalLogin';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';

import {
  EMAIL_SEARCH_PARAM,
  REGISTER_FROM_PARAM,
  REGISTER_OPTIN_PARAM,
  REGISTER_SIGNUP_PARAM,
  TYPE_SEARCH_PARAM,
} from '../../config/routes';
import { getDecryptedData } from '../../utils/crypto-utils';
import { finishProcess } from '../../utils/login';
import { getUUID } from '../../utils/utils';
import { getSearchParam } from '../../utils/routes';
import { getGenericError } from '../../utils/errors';
import {
  getRedirectPath,
  getURIParam,
  getViewType,
  getWildfireDeviceId,
} from './utils';

import './Login.scss';

// TODO - Implement URI whitelist from env var
/* const uriWhitelist = [
  'chrome-extension://extensionidhere/auth.html',
  'https://closet.fordays.com', // Only for testing purposes
]; */

const Login = () => {
  const { search } = useLocation();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData } = useFirebaseAuth();
  const setAlert = useAlert();
  const posthog = usePostHog();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState('');
  const [finishProcessData, setFinishProcessData] = useState({});

  const query = new URLSearchParams(search);
  const uriParam = getURIParam(query);
  const wfedidParam = getWildfireDeviceId(query);
  const typeParam = getSearchParam(query, TYPE_SEARCH_PARAM);
  const emailParam = getSearchParam(query, EMAIL_SEARCH_PARAM);

  const redirect = useCallback(async () => {
    if (!isSignedIn || !userData || isLoadingFirebaseAuth || isLoading) {
      return;
    }

    setIsLoading(true);

    const {
      uid: userUid,
      error,
    } = userData;

    if (error) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        error,
      });

      setIsLoading(false);
      return;
    }

    const data = await getDecryptedData(query, userUid);
    await finishProcess({
      ...finishProcessData,
      ...(REGISTER_SIGNUP_PARAM in data && { userRegistered: data[REGISTER_SIGNUP_PARAM] }),
      ...(REGISTER_OPTIN_PARAM in data && { optIn: data[REGISTER_OPTIN_PARAM] }),
      userData,
      search,
      wfExtensionFlow: uriParam && wfedidParam,
      wfedidParam,
      posthog,
    });

    window.location.href = getRedirectPath(
      query,
      getUUID(userData),
      history,
      view,
      data[REGISTER_FROM_PARAM],
    );
  }, [isSignedIn, userData, search, isLoadingFirebaseAuth, isLoading, finishProcessData, view]);

  const handleOnViewChange = useCallback((currentView) => {
    setView(currentView);
  }, []);

  useEffect(() => {
    if (isSignedIn && userData && !isLoadingFirebaseAuth) {
      redirect();
    }
  }, [isSignedIn, userData, isLoadingFirebaseAuth, isLoading]);

  return (
    <div className="Login">
      <GlobalLogin
        initialView={getViewType(typeParam)}
        initialEmail={emailParam}
        onViewChange={handleOnViewChange}
        isParentLoading={isLoading || isLoadingFirebaseAuth}
        setFinishProcessData={setFinishProcessData}
      />
    </div>
  );
};

export default Login;
